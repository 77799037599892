import { memo } from 'react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { IconFire } from '@/components/ui/icons/IconFire';
import { IconConfettiBackgroundOrange } from '@/components/ui/icons/IconConfettiBackgroundOrange';
import { FlashMessageBaseWrapper } from '@/components/ui/flashMessage/FlashMessageBaseWrapper';
import { type FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import styles from './StreakFlashMessageItem.module.scss';

type Props = FlashMessageProps;

export const StreakFlashMessageItem = memo<Props>(
  ({
    message,
    updateTimer,
    clearTimer,
    setTimer,
    removeMessage,
    closeDelay,
    setCloseDelay,
  }) => {
    const { t } = useTranslation([I18N_CODES.common]);

    return (
      <FlashMessageBaseWrapper
        data-qa={`flash-message-${message.type}`}
        message={message}
        updateTimer={updateTimer}
        clearTimer={clearTimer}
        setTimer={setTimer}
        removeMessage={removeMessage}
        closeDelay={closeDelay}
        setCloseDelay={setCloseDelay}
        withTimerAnimation
      >
        <IconConfettiBackgroundOrange className={styles.confettiBackground} />

        <div
          data-qa="flash-message-icon"
          className={styles.iconWrapper}
        >
          <IconFire className={styles.streakFireIcon} />
        </div>

        <div className={styles.contentWrapper}>
          <p
            data-qa="flash-message-heading"
            className={cn(
              typography.platformH3,
              styles.heading,
            )}
          >
            {t(`${I18N_CODES.common}:${message.heading}`)}
          </p>

          <p
            data-qa="flash-message-content"
            className={cn(
              typography.platformTextSecondary,
              styles.description,
            )}
          >
            {t(`${I18N_CODES.common}:${message.text}`)}
          </p>
        </div>
      </FlashMessageBaseWrapper>
    );
  },
);
