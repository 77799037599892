import { type FC } from 'react';
import { useQuery } from '@apollo/client';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { cn } from '@/lib/classNames';
import { type FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { VISIBLE_FLASH_MESSAGES } from '@/controllers/flashMessage/flashMessages.query';
import { FlashMessageItem } from '@/components/ui/flashMessage/FlashMessageItem';
import { FlashMessagePosition } from '@/components/ui/flashMessage/typedefs';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { EMPTY_ARRAY } from '@/constants';
import styles from './FlashMessageContainer.module.scss';

const positionClassNames: Record<FlashMessagePosition, string> = {
  [FlashMessagePosition.BottomRight]: styles.bottomRight,
  [FlashMessagePosition.IndentedBottomRight]: styles.indentedBottomRight,
};

interface Props {
  position?: FlashMessagePosition;
}

export const FlashMessageContainer: FC<Props> = ({
  position = FlashMessagePosition.BottomRight,
}) => {
  const { data } = useQuery(VISIBLE_FLASH_MESSAGES);
  const { removeMessage } = useFlashMessage();

  const visibleMessages = (
    data?.visibleFlashMessages?.visibleMessages || EMPTY_ARRAY
  );

  return (
    <div
      className={cn(
        styles.messageWrapper,
        positionClassNames[position],
      )}
    >
      <TransitionGroup className="message-list" data-qa="flash-message">
        {visibleMessages.map((message: FlashMessage) => (
          <CSSTransition
            timeout={350}
            classNames="message"
            key={message.id}
          >
            <FlashMessageItem
              message={message}
              removeMessage={removeMessage}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
