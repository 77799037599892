import {
  type Dispatch,
  type SetStateAction,
} from 'react';
import { type FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { type ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum FlashMessagePosition {
  BottomRight = 'bottom-right',
  IndentedBottomRight = 'indented-bottom-right',
}

export interface FlashMessageProps<T = any> {
  message: FlashMessage<T>;
  updateTimer: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>;
  clearTimer: () => void;
  setTimer: (delay: number) => NodeJS.Timeout;
  removeMessage: (message: FlashMessage) => void;
  closeDelay: number;
  setCloseDelay: (delay: number) => void;
}

export enum FlashMessageItemVariables {
  CloseDelay = '--close-delay',
  TimerLineColor = '--timer-line-color',
}

export interface FlashMessageHarness extends ComponentHarness {
  readonly messageType: FlashMessage['type'];
  readonly prefix: string;
  checkIsRendered: () => void;
}
