import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconConfettiBackground: FCIcon = (props) => (
  <BaseIcon
    width="408"
    height="152"
    viewBox="0 0 408 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M33.8578 24C30.3832 31.1912 24 29.6829 24 29.6829C24.1874 31.4029 24.4708 33.1117 24.849 34.802C24.849 34.802 35.6659 35.381 38.1343 25.6607C37.1595 25.3864 33.8578 24 33.8578 24Z" fill="#38C976" />
    <path d="M160.887 5.99895C156.534 6.97952 158.239 2.78397 158.239 2.78397C158.922 -0.431011 153.952 -1.57232 153.059 3.26622C152.924 4.30481 153.022 5.35992 153.345 6.35708C153.667 7.35424 154.207 8.26911 154.926 9.03711C156.55 10.6446 158.548 11.0947 161.959 10.4999C165.581 10.1624 165.824 4.87371 160.887 5.99895Z" fill="#22B0FC" />
    <path d="M324.52 10.4276C328.944 5.92802 331.563 5.88597 339.323 6.46068C340.649 6.50689 341.958 6.19728 343.072 5.5746C344.185 4.95192 345.046 4.04687 345.537 2.98438C345.781 3.15258 346.968 8.05866 346.968 8.05866C342.511 14.1282 335.581 8.75953 331.384 11.7592C330.262 12.5582 328.847 14.1282 328.131 14.5627L324.52 10.4276Z" fill="#FEC132" />
    <path d="M390.621 134.283L388.302 141.146C387.621 141.421 384.265 141.901 379.92 135.999C379.093 134.883 378.493 132.567 379.32 133.03C380.987 134.14 382.873 134.83 384.833 135.047C386.792 135.265 388.772 135.003 390.621 134.283Z" fill="#7557E6" />
    <path d="M379.77 135.338C379.996 136.362 381.563 127.324 376.54 123.424C376.023 123.029 372.372 127.935 371.823 128.474C374.747 129.552 379.286 133.397 379.77 135.338Z" fill="#7557E6" />
    <path d="M360.676 19.3155C356.323 18.3349 358.029 22.5305 358.029 22.5305C358.711 25.7455 353.741 26.8868 352.848 22.0482C352.713 21.0096 352.811 19.9545 353.134 18.9574C353.456 17.9602 353.996 17.0453 354.715 16.2773C356.339 14.6699 358.337 14.2197 361.748 14.8145C365.37 15.1521 365.613 20.4407 360.676 19.3155Z" fill="#22B0FC" />
    <path d="M125.542 24.2891C119.378 21.4859 115.049 27.5388 109.311 18.9326C107.868 15.3615 111.754 13.0582 114.607 16.9328C117.246 20.5038 124.723 16.0221 129.362 19.8789C132.166 22.2893 130.002 26.3246 125.542 24.2891Z" fill="#7557E6" />
    <path d="M338.614 137.412C346.006 133.859 350.207 138.616 350.207 138.616C351.159 137.194 352.021 135.724 352.789 134.212C352.789 134.212 344.37 127.678 336.16 133.771C336.802 134.476 338.614 137.412 338.614 137.412Z" fill="#E25544" />
    <path d="M395.386 66.6754C395.106 67.3189 396.425 72.31 406 74.1621L401.913 78.2429C393.936 77.3483 389.75 71.9647 389.371 68.449C389.371 68.449 395.452 66.5027 395.386 66.6754Z" fill="#FEC132" />
    <path d="M380.645 151.849C387.352 152.018 385.588 156.619 389.943 159.881C391.532 161.112 393.805 161.235 397.651 162.004C402.769 163.02 404.326 164.758 405.169 167.22C405.169 167.22 401.243 170.713 400.925 169.928C398.048 162.85 389.8 170.482 382.822 158.511C382.49 157.915 381.996 157.419 381.394 157.074C380.792 156.73 380.105 156.551 379.405 156.557C379.278 156.111 380.311 153.895 380.645 151.849Z" fill="#FEC132" />
  </BaseIcon>
);
