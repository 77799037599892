import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { type FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconError } from '@/components/ui/icons/IconError';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { WarningIcon } from '@/components/ui/icons/WarningIcon';
import { DEFAULT_CLOSE_DELAY, MIN_TIME_MS_PER_CHAR } from '@/components/ui/flashMessage/constants';

export const getIconByType = (type: FlashMessage['type']): FCIcon => {
  switch (type) {
    case MESSAGE_TYPES.success:
      return IconSuccess;
    case MESSAGE_TYPES.warning:
      return WarningIcon;
    case MESSAGE_TYPES.error:
      return IconError;
    case MESSAGE_TYPES.info:
    default:
      return IconInfo;
  }
};

export const getDelayTime = (
  messageDescription?: string,
): number => (
  Math.max(
    DEFAULT_CLOSE_DELAY,
    (messageDescription?.length || 0) * MIN_TIME_MS_PER_CHAR,
  )
);
