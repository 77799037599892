import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { type useFlashMessage } from '@/hooks/useFlashMessage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { type FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { DEFAULT_CLOSE_DELAY } from '@/components/ui/flashMessage/constants';
import { getDelayTime } from '@/components/ui/flashMessage/FlashMessageItem/helpers';
import { AchievementFlashMessageItem } from '@/components/ui/flashMessage/AchievementFlashMessage';
import { StreakFlashMessageItem } from '@/components/ui/flashMessage/StreakFlashMessage';
import { type FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import { DefaultFlashMessageItem } from '@/components/ui/flashMessage/DefaultFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';

type Props = {
  message: FlashMessage;
  removeMessage: ReturnType<typeof useFlashMessage>['removeMessage'];
};

const getFlashMessageItem = (
  type: FlashMessage['type'],
): FC<FlashMessageProps> => {
  switch (type) {
    case MESSAGE_TYPES.achievement:
      return AchievementFlashMessageItem;

    case MESSAGE_TYPES.streak:
      return StreakFlashMessageItem;

    default:
      return DefaultFlashMessageItem;
  }
};

export const FlashMessageItem = memo<Props>(({
  message,
  removeMessage,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const messageDescription = message.text
    || t(`${I18N_CODES.common}:${message.code}`);

  const [closeDelay, setCloseDelay] = useState<number>(
    getDelayTime(messageDescription),
  );

  const setTimer = (delay = DEFAULT_CLOSE_DELAY) => (
    setTimeout(() => removeMessage(message), delay)
  );

  const [timer, updateTimer] = useState<NodeJS.Timeout>();

  const clearTimer = () => {
    clearTimeout(timer);
  };

  useEffect(() => {
    updateTimer(setTimer(closeDelay));

    return clearTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FlashMessageItemComponent = getFlashMessageItem(
    message.type,
  );

  return (
    <FlashMessageItemComponent
      removeMessage={removeMessage}
      message={message}
      setTimer={setTimer}
      clearTimer={clearTimer}
      updateTimer={updateTimer}
      closeDelay={closeDelay}
      setCloseDelay={setCloseDelay}
    />
  );
});
